import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import ContactForm from '../components/ContactForm'


const META = {
  TITLE: 'MisterGoodBeer - Mentions légales',
  DESCRIPTION: 'Mention légales de MisterGoodBeer',
  KEYWORDS: 'MisterGoodBeer bières pas chères bars pas chers happy hour mention légales',
}

const LegalPage = () => (
  <Layout>
    <Helmet
      title={META.TITLE}
      meta={[
        { name: 'description', content: META.DESCRIPTION },
        { name: 'keywords', content: META.KEYWORDS },
      ]}
    />

    <section className="section">
      <div className="container">
        <div className="content">
          <h1 className="title">
            Mentions légales
          </h1>
          <p>
            Conformément aux dispositions de l’article 6-I-1 de la loi
            n°2004-575
            du 21 juin 2004 relative à la confiance dans l’économie numérique,
            l’éditeur de ce
            site est :
            MisterGoodBeer, société par action simplifiée, au capital de 5 000
            euros, immatriculée au
            registre du commerce et des sociétés de Paris, sous le numéro 819
            884
            693, dont le siège
            est situé 37, avenue de Lowendal 75015 Paris.
          </p>
          <p>
            Le directeur de la publication du site web www.mistergoodbeer.fr,
            est
            Monsieur Martin Bompaire, en sa qualité de Président, à l’adresse
            Mistergoodbeer, 37, avenue de Lowendal 75015 Paris.
          </p>
          <p>
            Le prestataire assurant le stockage direct et permanent est la
            société
            1&1 Internet SARL , 7, place de la Gare BP 70109, 57201
            Sarreguemines
            Cedex. Numéro de téléphone : 0970 808 911
          </p>
          <h2 className="title is-5">
            1. Description des services fournis :
          </h2>
          <p>
            L'application MisterGoodBeer, et le site internet
            www.mistergoodbeer.com ont pour objet de fournir une information
            concernant les prix des bières dans le bars et cafés, localisés sur
            une carte.
          </p>
          <p>
            Le propriétaire de l'application et du site internet s’efforce de
            fournir sur ceux-ci des informations aussi précises que possible.
            Toutefois, il ne pourra être tenu responsable des omissions, des
            inexactitudes et des carences dans la mise à jour, qu’elles soient
            de son fait ou du fait des utilisateurs qui lui fournissent ces
            informations.
          </p>
          <p>
            Tous les informations proposées sur l'application MisterGoodBeer et
            le site www.mistergoodbeer.com sont données à titre indicatif, sont
            non exhaustives, et sont susceptibles d’évoluer. Elles sont données
            sous réserve de modifications ayant été apportées depuis leur mise
            en ligne.
          </p>
          <h2 className="title is-5">
            2. Propriété intellectuelle et contrefaçons :
          </h2>
          <p>
            Le propriétaire de l'application est propriétaire des droits de
            propriété intellectuelle ou détient les droits d’usage sur tous les
            éléments accessibles sur le site, notamment les textes, images,
            graphismes, logo, icônes, sons, logiciels…
          </p>
          <p>
            Toute reproduction, représentation, modification, publication,
            adaptation totale ou partielle des éléments de l'application, quel
            que
            soit le moyen ou le procédé utilisé, est interdite, sauf
            autorisation écrite préalable à l'email :
            contact@mistergoodbeer.com
          </p>
          <p>
            Toute exploitation non autorisée de l'application ou de l’un
            quelconque de ces éléments qu’il contient sera considérée comme
            constitutive d’une contrefaçon et poursuivie conformément aux
            dispositions des articles L.335-2 et suivants du Code de Propriété
            Intellectuelle.
          </p>
          <h2 className="title is-5">
            3. Protection des biens et des personnes - gestion des données
            personnelles :
          </h2>
          <p>
            Utilisateur : utilisateur de téléphone connecté téléchargeant,
            utilisant l'application susnommée : MisterGoodBeer.
            En France, les données personnelles sont notamment protégées par la
            loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
            l'article L. 226-13 du Code pénal et la Directive Européenne du 24
            octobre 1995.
          </p>
          <p>
            Sur l'application MisterGoodBeer, le propriétaire ne collecte des
            informations personnelles relatives à l'utilisateur que pour le
            besoin de certains services proposés par l'application.
            L'utilisateur fournit ces informations en toute connaissance de
            cause, notamment lorsqu'il procède par lui-même à leur saisie. Il
            est alors précisé à l'utilisateur de l'application MisterGoodBeer
            l’obligation ou non de fournir ces informations. Conformément aux
            dispositions des articles 38 et suivants de la loi 78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux
            libertés, tout utilisateur dispose d’un droit d’accès, de
            rectification, de suppression et d’opposition aux données
            personnelles le concernant. Pour l’exercer, adressez votre demande à
            par email : dev@mistergoodbeer.com ou en effectuant sa demande
            écrite et signée, accompagnée d’une copie du
            titre d’identité avec signature du titulaire de la pièce, en
            précisant l’adresse à laquelle la réponse doit être envoyée.
          </p>
          <p>
            Aucune information personnelle de l'utilisateur de l'application
            n'est
            publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou
            vendue sur un support quelconque à des tiers. Seule l'hypothèse du
            rachat de l'application au propriétaire du site et de ses droits
            permettrait la transmission des dites informations à l'éventuel
            acquéreur qui serait à son tour tenu de la même obligation de
            conservation et de modification des données vis à vis de
            l'utilisateur
            de l'application MisterGoodBeer.
            L'application MisterGoodBeer est déclarée à la CNIL sous le numéro
            1970927 v 0.
          </p>
          <p>
            Les bases de données sont protégées par les dispositions de la loi
            du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
            relative à la protection juridique des bases de données.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="content">
          <h2>
            Nous contacter
          </h2>
          <p>
            Pour toute demande ou réclamation, vous pouvez nous contacter en
            remplissant les champs ci-dessous
          </p>
          <ContactForm />
        </div>
      </div>
    </section>

  </Layout>
)

export default LegalPage
